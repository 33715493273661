import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Marker } from 'react-leaflet';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import React from 'react';
import SiteTemplate from 'SiteTemplate';
import { Button, Image } from 'react-bootstrap';
import { Icon, LatLngBounds } from 'leaflet';

function Meetup() {
    let [searchParams] = useSearchParams();
    let lat = searchParams.get('lat');
    let long = searchParams.get('long');
    let metupPosition = [Number(lat), Number(long)];
    let hasValidParams = lat !== null && long !== null;

    const [userPosition, setUserPosition] = useState({
        known: false,
        lat: 0,
        long: 0,
    });
    const [locationUpdateRequired] = useState(false);
    let mapRef = useRef();
    let positionKnown = useRef(false);

    const handlePosition = (position) => {
        const firstUpdate = !positionKnown.current;
        if (firstUpdate) {
            const l = new LatLngBounds([
                [position.coords.latitude, position.coords.longitude],
                [metupPosition[0], metupPosition[1]],
            ]);
            //console.log('Bounding box is', l);
            if (mapRef !== undefined && mapRef.current !== undefined) {
                mapRef.current.fitBounds(l);
            }
        }
        setUserPosition({
            known: true,
            lat: position.coords.latitude,
            long: position.coords.longitude,
        });
        positionKnown.current = true;

        //console.log(`Position is ${position.coords.latitude}, ${position.coords.longitude}`)
    };
    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(
            handlePosition,
            (error) => console.error(error.message),
            {
                enableHighAccuracy: true,
                timeout: Infinity,
                maximumAge: 1000, // cache position for 1 second
            }
        );
    }

    /**
     * This function gets the users current location and sets it in the
     * userPosition component state. It is called on a timer every 5
     * seconds.
     *
     */
    useEffect(() => {
        getLocation();
        let getLocationInterval = setInterval(getLocation, 5000);
        return () => {
            clearInterval(getLocationInterval);
        };
    }, [locationUpdateRequired]);

    const redIcon = new Icon({
        iconUrl:
            '/marker-icon-green.png',
        shadowUrl:
            'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    const blueIcon = new Icon({
        iconUrl:
            '/marker-icon-blue.png',
        shadowUrl:
            'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    return (
        <SiteTemplate>
            <>
                {hasValidParams && (
                    <>
                        <div className="d-grid gap-2 mt-2 mb-2">
                            <Button
                                variant="primary"
                                href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`}
                            >
                                Open in Google Maps
                            </Button>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <p>
                                Meetup location:{' '}
                                <Image src="/marker-icon-green.png" />
                            </p>
                            <p>
                                Your current location:{' '}
                                <Image src="/marker-icon-blue.png" />
                            </p>
                        </div>

                        <MapContainer
                            center={metupPosition}
                            zoom={16}
                            scrollWheelZoom={false}
                            ref={mapRef}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <Marker
                                position={metupPosition}
                                icon={redIcon}
                                alt="Meetup here!"
                            ></Marker>
                            {userPosition.known && (
                                <>
                                    <Marker
                                        position={[
                                            userPosition.lat,
                                            userPosition.long,
                                        ]}
                                        icon={blueIcon}
                                        alt="You are here!"
                                    ></Marker>
                                    {/**
                                     * Add arrow from start to end point here
                                     **/}
                                </>
                            )}
                        </MapContainer>
                    </>
                )}
                {!hasValidParams && <>Invalid Request</>}
            </>
        </SiteTemplate>
    );
}

export default Meetup;
