import React from 'react';
import { Button } from 'react-bootstrap';
import SiteTemplate from 'SiteTemplate';

function Greeting() {
    return (
        <SiteTemplate>
            <>
                <p>Hassle free meet up point generator.</p>
                <Button
                    variant="primary"
                    href="/meetmehere"
                    type="submit"
                >
                    Meetup at My Current Position
                </Button>
            </>
        </SiteTemplate>
    );
}

export default Greeting;
