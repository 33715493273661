import { useEffect, useState } from 'react';

import { Marker } from 'react-leaflet';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import React from 'react';
import SiteTemplate from 'SiteTemplate';
import { Button, Form } from 'react-bootstrap';
import Constants from 'Constants';

function MeetHere() {
    const [userPosition, setUserPosition] = useState({
        known: false,
        lat: 0,
        long: 0,
        error: false,
    });

    const [lat, long] = [userPosition.lat, userPosition.long];
    const invitationText =
        !userPosition.known || userPosition.error
            ? ''
            : `Lets Meet Up!

View in Lets Meet Up
${window.location.origin}/meetup?lat=${lat}&long=${long}&v=${Constants.version}

View in Google Maps
https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`;
    const [locationUpdateRequired] = useState(false);

    /**
     * This function gets the users current location and sets it in the
     * userPosition component state. It is called on a timer every 5
     * seconds.
     *
     */
    useEffect(() => {
        function getLocation() {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserPosition({
                        known: true,
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                        error: false,
                    });
                },
                (error) => {
                    console.error(error.message);
                    setUserPosition({
                        known: false,
                        lat: 0,
                        long: 0,
                        error: true,
                    });
                },
                {
                    enableHighAccuracy: true,
                    timeout: Infinity,
                    maximumAge: 1000, // cache position for 1 second
                }
            );
        }
        getLocation();
        let getLocationInterval = setInterval(getLocation, 5000);

        return () => {
            clearInterval(getLocationInterval);
        };
    }, [locationUpdateRequired]);

    const [map, setMap] = useState(null);
    useEffect(() => {
        if (map && userPosition.known) {
            map.setView([userPosition.lat, userPosition.long]);
        }
    });

    return (
        <SiteTemplate>
            <>
                {userPosition.known && (
                    <>
                        <MapContainer
                            center={[userPosition.lat, userPosition.long]}
                            zoom={16}
                            scrollWheelZoom={false}
                            ref={setMap}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            {userPosition.known && (
                                <Marker
                                    position={[
                                        userPosition.lat,
                                        userPosition.long,
                                    ]}
                                    alt="You are here!"
                                ></Marker>
                            )}
                        </MapContainer>

                        <div className="d-grid gap-2 mt-2">
                            <Button
                                variant="primary"
                                href={`${window.location.origin}/meetup?lat=${lat}&long=${long}&v=${Constants.version}`}
                            >
                                View Meetup URL
                            </Button>

                            <Button
                                variant="primary"
                                href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`}
                            >
                                Open in Google Maps
                            </Button>
                        </div>

                        <Form>
                            <Form.Group
                                className="mb-3 mt-3"
                                controlId="meetupInvitationText"
                            >
                                <Form.Label>Meetup Invitation</Form.Label>
                                <Form.Control
                                    disabled={true}
                                    as="textarea"
                                    rows={7}
                                    value={invitationText}
                                />
                            </Form.Group>
                            <div className="d-grid gap-2 mt-2">
                                <Button
                                    className="mb-5"
                                    variant="primary"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            invitationText
                                        );
                                    }}
                                >
                                    Copy To Clipboard
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
                {!userPosition.known && userPosition.error && (
                    <>Couldn't find your location, please reload the page?</>
                )}
                {!userPosition.known && !userPosition.error && (
                    <>Finding position...</>
                )}
            </>
        </SiteTemplate>
    );
}

export default MeetHere;
