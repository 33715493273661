import React from 'react';
import { Card } from 'react-bootstrap';

const SiteTemplate = (props) => (
    <Card>
        <Card.Body>
            <Card.Title>Lets Meet Up!</Card.Title>
            {props.children}
        </Card.Body>
    </Card>
);

export default SiteTemplate;
