import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Meetup from './Meetup';
import MeetHere from './MeetHere';
import 'bootstrap/dist/css/bootstrap.min.css';
import Greeting from 'Greeting';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Greeting />}></Route>
                <Route path="meetmehere" element={<MeetHere />}></Route>
                <Route path="meetup" element={<Meetup />} />
                <Route
                    path="*"
                    element={
                        <main style={{ padding: '1rem' }}>
                            {/**
                             * This should be it's own component
                             */}
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
